@import '../../mixins';

.advantages {
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);

    @include media(#{rem(1200)}) {
      display: flex;
      flex-direction: column;
    }
  }

  & .advantages-card {
    width: 100%;

    @include mediaTablet {
      min-height: rem(196);
    }
  }

  &_icon {
    & .advantages {
      &__content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @include media(#{rem(1200)}) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include media(#{rem(520)}) {
          display: flex;
          flex-direction: column;
          gap: rem(15);
        }
      }
      &-icon {
        width: 100%;

        &_text {
          @include media(#{rem(520)}) {
            margin-bottom: rem(10);
          }
        }
      }
    }
  }
}
